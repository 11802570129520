// eslint-disable-next-line no-unused-vars
import React, { Component } from 'react';
import { observer } from 'mobx-react';
import {
  Container, Row,
} from 'react-bootstrap';
import { LinearProgress } from '@material-ui/core';
import ProductStore from '../../stores/ProductStore';
import DefaultTable from '../common/Tables/DefaultTable';
import AlertTemplate from '../common/AlertTemplate';

class ProductApp extends Component {
  async componentDidMount() {
    try {
      await ProductStore.loadProducts();
    } catch (e) {
      // this.props.alert.show(e.message, { type: 'danger' });
      console.log(e.message);
      ProductStore.set('alert', {
        show: true,
        variant: 'danger',
        msg: `Error! ${e.message}`,
      });
    } finally {
      console.log('finallay');
    }
  }

  render() {
    return (
      <Container>
        {ProductStore.loading && <LinearProgress variant="query" />}
        {/* <AlertTemplate show={ProductStore.showAlert} setShow={ProductStore.set} /> */}
        <AlertTemplate alert={ProductStore.alert} setShow={ProductStore.set} />
        <Row >
          <h2> Test</h2>
          {/* <ProductList productStore={ProductStore} /> */}
         <DefaultTable items={ProductStore.products} tableHead={ProductStore.productsListHead}/>
         {/* <Table striped bordered hover size="sm">
        <DefaultTableHead tableHead={ProductStore.productsListHead} />
        <tbody>
          { ProductStore.products.map((item, index) => (
              <DefaultTableBody key={index} {...item} tableHead={ProductStore.productsListHead}/>
          )) }
          <tr>
            <td>1</td>
            <td>Mark</td>
            <td>Otto</td>
            <td>@mdo</td>
          </tr>
          <tr>
            <td>2</td>
            <td>Jacob</td>
            <td>Thornton</td>
            <td>@fat</td>
          </tr>
          <tr>
            <td>3</td>
            <td colSpan="2">Larry the Bird</td>
            <td>@twitter</td>
          </tr>
        </tbody>
      </Table> */}
        </Row>
      </Container>
    );
  }
}

export default observer(ProductApp);
