// eslint-disable-next-line no-unused-vars
import React, { Component } from 'react';

class Home extends Component {
  render() {
    return (
      <div> I am home
      </div>
    );
  }
}

export default Home;
