import React from 'react'; // eslint-disable-line no-unused-vars
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Icon from '@material-ui/core/Icon';
import Button from '@material-ui/core/Button';
// import { withAlert } from 'react-alert';
import { makeStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react';
// import PropTypes from 'prop-types';
import ProductStore from '../../stores/ProductStore';
import constants from '../../constants';
import AlertTemplate from '../common/AlertTemplate';
import textcontent from '../../content';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  dense: {
    marginTop: theme.spacing(2),
  },
  menu: {
    width: 200,
  },
  button: {
    margin: theme.spacing(1),
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  iconSmall: {
    fontSize: 20,
  },
}));


const AddProduct = () => {
  const classes = useStyles();
  const handleChange = name => (event) => {
    if (name === 'productId') {
      ProductStore.product[name] = (event == null) ? '' : event.value;
    } else if (name === 'productName') {
      ProductStore.setObj('product', name, event.target.value);
      // ProductStore.product[name] = event.target.value;
    }
  };

  const isRequireProductEmpty = () => {
    if (ProductStore.product.productName === '') {
      return true;
    }
    return false;
  };

  const clearProduct = (e) => {
    e.preventDefault();
    ProductStore.setObj('product', 'productName', '');
  };

  const saveProduct = async (e) => {
    e.preventDefault();
    try {
      if (isRequireProductEmpty()) {
        ProductStore.set('alert', {
          show: true,
          variant: 'danger',
          msg: 'Error! Input Fields marked with * are required',
        });
      } else {
        const result = await ProductStore.saveProduct();
        if (result.transaction_id) {
          // this.props.alert.show('Charge saved', { type: 'success' });
          // this.props.chargeStore.resetCharge();
        } else if (result.statusCode === 400) { // bad request
          // this.props.alert.show(result.message, { type: 'danger' });
        }
      }
    } catch (err) {
      // this.props.alert.show(err.message, { type: 'danger' });
      console.log(err);
    }
  };


  const content = <div>
    <AlertTemplate alert={ProductStore.alert} setShow={ProductStore.set} />
    {/* We already have an h1 in the page, let's not duplicate it. */}
    <Typography variant="h5" component="h2">
      {constants.str.addProduct.engMsg}{/*  Add Product */}
    </Typography>
    <Typography noWrap>
      {textcontent.appContent.required}
    </Typography>
    <form className={classes.container} noValidate autoComplete="off">
      <div>
        <TextField
          id="outlined-product-name-input"
          label="Product Name*"
          className={classes.textField}
          type="text"
          name="productName"
          autoComplete="productName"
          margin="dense"
          variant="outlined"
          value={ProductStore.product.productName}
          onChange={handleChange('productName')}
        />
      </div>
      <div>
        <Button className={classes.button}
          variant="contained"
          color="primary"
          onClick={saveProduct}>Save <Icon className={classes.rightIcon}>save</Icon>
        </Button>
        <Button className={classes.button} variant="contained" color="secondary"
          onClick={clearProduct}>
          Clear <Icon className={classes.rightIcon}>cancel</Icon>
        </Button>
      </div>
    </form>
  </div>;
  return (<div>
    {ProductStore.loading && <LinearProgress mode="query" />}
    {content}
  </div>);
};

export default observer(AddProduct);
