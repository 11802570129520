import moment from 'moment';

const utils = {
  // Check object is empty or now.
  isEmpty: (obj) => {
    const { hasOwnProperty } = Object.prototype;
    // null and undefined are "empty"
    if (obj == null) return true;

    // Assume if it has a length property with a non-zero value
    // that that property is correct.
    if (obj.length > 0) return false;
    if (obj.length === 0) return true;

    // If it isn't an object at this point
    // it is empty, but it can't be anything *but* empty
    // Is it empty?  Depends on your application.
    if (typeof obj !== 'object') return true;

    // Otherwise, does it have any properties of its own?
    // Note that this doesn't handle
    // toString and valueOf enumeration bugs in IE < 9
    // eslint-disable-next-line no-restricted-syntax
    for (const key in obj) {
      if (hasOwnProperty.call(obj, key)) return false;
    }
    return true;
  },
  getUniqueArray: (array, comp) => {
    const unique = array
      .map(e => e[comp])

    // store the keys of the unique objects
      .map((e, i, final) => final.indexOf(e) === i && i)

    // eliminate the dead keys & store unique objects
      .filter(e => array[e]).map(e => array[e]);

    return unique;
  },
  stringToInteger: (value) => {
    if (value === '') {
      return 0;
    }
    return parseInt(value, 10);
  },
  sortDates: (a, b, order) => {
    // Move empty date rows to bottom.
    const ascEmptyDate = moment().add('years', 10).valueOf();
    if (order === 'asc') {
      let r = 0;
      r = (a ? moment(a, 'DD/MM/YY').valueOf() : ascEmptyDate)
        - (b ? moment(b, 'DD/MM/YY').valueOf() : ascEmptyDate);
      return r;
    } if (order === 'desc') {
      let r = 0;
      r = (b ? moment(b, 'DD/MM/YY').valueOf() : 0)
        - (a ? moment(a, 'DD/MM/YY').valueOf() : 0);
      return r;
    }
    return 0;
  },
  formatDate: date => moment.utc(date).format('DD MMM YYYY HH:mm'),
};

export default utils;
