// eslint-disable-next-line
import config from './config';
// eslint-disable-next-line
// import adalHelper from './adalHelper';

const api = {
  getProducts: async () => api.requestsFetch('/products', 'GET'),
  getProduct: async id => api.requestsFetch(`/product/${id}`, 'GET'),
  postProduct: async (id, data) => api.requestsFetch(`/product/${id}`, 'POST', data),
  putProduct: async (productId, data) => api.requestsFetch(`/product/${productId}`, 'PUT', data),
  deleteProduct: async id => api.requestsFetch(`/product/${id}`, 'DELETE'),
  getCustomers: async () => api.requestsFetch('/customers', 'GET'),
  getSales: async () => api.requestsFetch('/sales', 'GET'),
  getVendors: async () => api.requestsFetch('/vendors', 'GET'),
  getStocks: async () => api.requestsFetch('/stocks', 'GET'),
  // requestsAppApiToken: async () => {
  //   try {
  //     return await adalHelper.acquireToken();
  //   } catch (e) {
  //     throw e;
  //   }
  // },
  requestsFetch: async (urlFragment, method, data, isFile) => {
    // const token = await api.requestsAppApiToken();
    const url = `${config.apiUrl}${urlFragment}`;
    const options = {
      method: method,
      headers: {
        Authorization: `${config.headers.Authorization}`,
      },
      body: data ? JSON.stringify(data) : null,
    };
    return api.fetchAndDeserialise(url, options, isFile);
  },

  fetchAndDeserialise: async (url, options, isFile) => {
    let response;
    try {
      response = await fetch(url, options);
    } catch (error) {
      console.error(`Error fetching ${url}`, error);
      throw error;
    }
    if (response.status === 204) {
      return response;
    }
    if (isFile) {
      response.data = await response.blob();
    } else {
      response.data = await response.json();
    }

    return response;
  },

};

export default api;
