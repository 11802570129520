import React from 'react'; // eslint-disable-line no-unused-vars
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import NotificationsIcon from '@material-ui/icons/Notifications';
import Badge from '@material-ui/core/Badge';
import MenuIcon from '@material-ui/icons/Menu';
import clsx from 'clsx';

class TopBar extends React.Component {
  constructor(props) {
    super(props);
    this.handleDrawerOpen = () => {
      this.props.handleDrawerOpen();
    };
  }

  render() {
    const { classes } = this.props;
    return (
      <AppBar position="absolute"
        className={clsx(classes.appBar, this.props.open && classes.appBarShift)}>
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={this.handleDrawerOpen}
            className={clsx(classes.menuButton, this.props.open && classes.menuButtonHidden)}
          >
            <MenuIcon />
          </IconButton>
          <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
            Dashboard
          </Typography>
          <IconButton color="inherit">
            <Badge badgeContent={4} color="secondary">
              <NotificationsIcon />
            </Badge>
          </IconButton>
        </Toolbar>
      </AppBar>
      // <AppBar type="static" className={classes.appBar}>
      // <Toolbar>
      //   <IconButton
      //     color="inherit"
      //     aria-label="open drawer"
      //     onClick={this.handleDrawerToggle}
      //     className={classes.navIconHide}>
      //     <MenuIcon />
      //   </IconButton>
      //   <IconButton color="inherit">
      //     <img className="appIcon" src="/img/massey-logo.png" alt="Massey Logo" />
      //   </IconButton>
      //   <Typography type="title" color="inherit" >
      //   {appContent.appTitle}
      //   </Typography>
      //   <div className={classes.versionBtn} color="inherit">v2019.4</div>
      //   <div className={classes.profile}>{adalHelper.getCachedUser().profile.name}</div>
      // </Toolbar>
      // </AppBar>
    );
  }
}


TopBar.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default TopBar;
