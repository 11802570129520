// eslint-disable-next-line no-unused-vars
import React, { Component } from 'react';
import { Alert, Button } from 'react-bootstrap';
import { observer } from 'mobx-react';

class AlertTemplate extends Component {
  constructor(props) {
    super(props);

    this.countDown = () => {
      let number = this.state.timer;
      if (number > 0) {
        number -= 1;
        this.setState({
          timer: number,
        });
      } else {
        this.state = {
          timer: 8,
        };
      }
      console.log('timer', number);
    };

    this.startCounDown = () => {
      this.props.alert.show = true;
      // this.props.setShow('showAlert', true);
      this.countDownTrack = setInterval(() => {
        this.countDown();
        if (this.state.timer < 1) {
          clearInterval(this.countDownTrack);
          this.props.setShow('showAlert', false);
          // this.state = {
          //   timer: 8,
          // };
        }
      }, 1000);
    };
    this.state = {
      timer: 8,
    };
  }

  componentDidMount() {
    // this.startCounDown();
  }

  render() {
    const {
      alert,
      setShow,
    } = this.props;
    // const timeout = 10;
    return (
      <>
      <Alert show={alert.show} variant={alert.variant}
      onClose={() => setShow('alert', { show: false })} dismissible>
        {!alert.msgHeading && <Alert.Heading>{alert.msgHeading}</Alert.Heading>}
        <p>{alert.msg}</p>
        {alert.closeBtn && <div>
          <hr />
          <div className="d-flex justify-content-end">
            <Button onClick={() => setShow('showAlert', false)} variant="outline-success">
              Close {this.state.timer}
            </Button>
          </div>
        </div>
        }
      </Alert>
      {/* {show && setTimeout(() => setShow('showAlert', false), 5000) } */}
      {/* {show && this.startCounDown()} */}
      {/* {!alert.show && <Button onClick={() => this.startCounDown()}>Show Alert </Button>} */}
    </>
    );
  }
}

export default observer(AlertTemplate);
