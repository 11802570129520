import {
  decorate, observable, action, configure,
} from 'mobx';
import api from '../api';
import BaseStore from './BaseStore';
import utils from '../utils/commonUtil';

configure({ enforceActions: 'always' }); // strict mode always - use 'false' => '"never"', 'true' => '"observed"', '"strict"' => "'always'" instead

class VendorStore extends BaseStore {
  constructor(props) {
    super(props);
    this.initBase(); // triggering from BaseStore
    this.init = () => {
      // specific
      this.items = [];
      // cuRd = READ helper for view
      this.listHead = [
        { name: 'id', title: 'ID', sort: false },
        { name: 'name', title: 'Name', sort: false },
        { name: 'created', title: 'Created', sort: true },
      ];
    };
    this.init();
  }

  async fetchItems() { // this method is overriden
    const itemList = await api.getVendors();
    const itemSorted = itemList.data.sort((a, b) => {
      if (a.vendorName < b.vendorName) return -1;
      if (a.vendorName > b.vendorName) return 1;
      return 0;
    });

    itemSorted.forEach((item) => {
      this.push(this.items, {
        id: item.vendorId,
        name: item.vendorName,
        created: utils.formatDate(item.created),
      });
    });
  }
}
decorate(VendorStore, {
  init: action,
  items: observable,
  listHead: observable,
});

export default new VendorStore();
