import {
  decorate, observable, action, configure,
} from 'mobx';
import api from '../api';
import utils from '../utils/commonUtil';
import BaseStore from './BaseStore';

configure({ enforceActions: 'always' }); // strict mode always - use 'false' => '"never"', 'true' => '"observed"', '"strict"' => "'always'" instead

class StockStore extends BaseStore {
  constructor(props) {
    super(props);
    this.initBase(); // triggering from BaseStore
    this.init = () => {
      // specific
      this.items = [];
      // cuRd = READ helper for view
      this.listHead = [
        { name: 'id', title: 'ID', sort: false },
        { name: 'stock', title: 'Stock', sort: false },
        { name: 'quantity', title: 'Quantity', sort: false },
        { name: 'price', title: 'Price', sort: false },
        { name: 'date', title: 'Date', sort: false },
        { name: 'product', title: 'Product', sort: false },
        { name: 'vendor', title: 'Vendor', sort: false },
        { name: 'created', title: 'Created', sort: true },
      ];
    };
    this.init();
  }

  async fetchItems() { // this method is overriden
    const itemList = await api.getStocks();
    const itemSorted = itemList.data.sort((a, b) => {
      if (a.stockId < b.stockId) return -1;
      if (a.stockId > b.stockId) return 1;
      return 0;
    });

    itemSorted.forEach((item) => {
      this.push(this.items, {
        id: item.stockId,
        stock: item.stockName,
        quantity: item.stockQuantity,
        price: item.stockPrice,
        date: utils.formatDate(item.stockDate),
        product: item.stockProduct,
        vendor: item.stockVendor,
        created: utils.formatDate(item.created),
      });
    });
  }
}
decorate(StockStore, {
  init: action,
  items: observable,
  listHead: observable,
});

export default new StockStore();
