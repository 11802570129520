// eslint-disable-next-line no-unused-vars
import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Table } from 'react-bootstrap';
import DefaultTableBody from './DefaultTableBody';
import DefaultTableHead from './DefaultTableHead';

class DefaultTable extends Component {
  render() {
    return (
      <Table striped bordered hover size="sm">
      <DefaultTableHead tableHead={this.props.tableHead} />
      <tbody>
        { this.props.items.map((item, index) => (
            <DefaultTableBody key={index} {...item} tableHead={this.props.tableHead}/>
        )) }
        <tr>
          <td>1</td>
          <td>Mark</td>
          <td>Otto</td>
          <td>@mdo</td>
        </tr>
        <tr>
          <td>2</td>
          <td>Jacob</td>
          <td>Thornton</td>
          <td>@fat</td>
        </tr>
        <tr>
          <td>3</td>
          <td colSpan="2">Larry the Bird</td>
          <td>@twitter</td>
        </tr>
      </tbody>
    </Table>
    );
  }
}

export default observer(DefaultTable);
