const constants = {
  str: { addProduct: { engMsg: 'Add Product', urduMsg: 'پروڈکٹ' } }, // constants.str.code

  // TODO change to object.
  validationErrorMessages: [
    { id: 0, code: 'atLeastOne', message: 'You must have at least one row.' }, // TODO: may be best to have code to call with code is more readable then number
    { id: 1, message: 'You must select an option.' },
    { id: 2, message: 'You must enter a value.' },
    { id: 3, message: 'You must select a date range.' },
    {
      id: 4, message: `Annual leave days exceed working days.
    This affects Massey funding for this leave request.`,
    },
    { id: 5, message: 'Dates are out of range.' },
    { id: 6, message: 'Dates should only be assigned one day type.' },
    { id: 7, message: 'You must enter a number.' },
    {
      id: 8,
      message: 'You must upload a file.',
    },
    { id: 9, message: 'You must tick the box.' },
  ],
};

export default constants;
