import React from 'react';
// import config from '../../config';

class Footer extends React.Component {
  render() {
    return (
      <div className="text-light p-3">
          {/* {config.jsVersionTag} {config.environment} */}
          Footer
      </div>
    );
  }
}

export default Footer;
