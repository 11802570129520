// eslint-disable-next-line no-unused-vars
import React, { Component } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { Container } from 'reactstrap';
import { withStyles } from '@material-ui/core/styles';
import 'bootstrap/dist/css/bootstrap.css';
// import logo from './logo.svg';
import './App.css';
import Home from './components/Home';
import SignIn from './components/SignIn';
import Header from './components/common/Header';
import Footer from './components/common/Footer';
// import Loading from './components/common/Loading';
import ProductApp from './components/product/List';
import ProductAdd from './components/product/Add';
import CustomerApp from './components/customer/List';
import SaleApp from './components/sale/List';
import StockApp from './components/stock/List';
import VendorApp from './components/vendor/List';
import SideBar from './components/common/SideBar';
import 'typeface-roboto';

const drawerWidth = 240;

const styles = theme => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
});

class App extends Component {
  render() {
    const { classes } = this.props;
    let content;
    content = '';
    // if (!adalHelper.userHasRole(config.applicationRoles.User)
    // && !adalHelper.userHasRole(config.applicationRoles.Admin)) {
    //   content = <NoAccess/>;
    // } else {
    content = <Container fluid={true} className="p-0">
            {/* <Loading /> */}
            <Header />
            <Route path='/' exact={true} component={() => <Home />} />
            <Route path='/signin' exact={true} component={() => <SignIn />} />
            <Route path='/product/list' exact={true} component={() => <ProductApp />} />
            <Route path='/customer/list' exact={true} component={() => <CustomerApp />} />
            <Route path='/vendor/list' exact={true} component={() => <VendorApp />} />
            <Route path='/sale/list' exact={true} component={() => <SaleApp />} />
            <Route path='/stock/list' exact={true} component={() => <StockApp />} />
            <Route path='/product/add' exact={true} component={() => <ProductAdd />} />
            {/* <Route path='/leave/create' exact={true} component={() => <Create />} />
            <Route path='/manage-users' exact={true} component={() => <ManageUser />} />
            <Route path='/approval-chain' exact={true} component={() => <ApprovalChain />} />
            <Route path='/leave/edit/:id' exact={true} render={props => (
              <Edit {...this.props} {...props} />
            )} />
            <Route path='/leave/view/:id' exact={true} render={props => (
            <View {...this.props} {...props} />
            )} />
            <Route path='/leave/:leaveid/approval/:id' exact={true} render={props => (
            <ReviewApprove {...this.props} {...props} />
            )} /> */}
            <Footer />
          </Container>;
    // }
    // const classes = useStyles();
    // const classes = {};
    return (
      <div className={classes.root}>
        <CssBaseline />
        <Router>
        <SideBar classes={classes}/>
        <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxwidth="lg" className={classes.container}>
        {content}
        </Container>
      </main>
      </Router>
      </div>
    );
  }
}
export default withStyles(styles)(App);
