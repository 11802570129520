// eslint-disable-next-line no-unused-vars
import React, { Component } from 'react';
import { observer } from 'mobx-react';

class DefaultTableBody extends Component {
  constructor(props) {
    super(props);
    console.log(props.tableHead);
  }

  render() {
    return (
      <tr>
        <td>@</td>{/* TODO: Checkbox */}
         {/* <td>{this.props.id}</td>
          <td>{this.props.name}</td>
          <td>{this.props.crated}</td> */}
         { this.props.tableHead.map((col, index) => (
           <td key={index}>{this.props[col.name]}</td>
         )) }
       </tr>
    );
  }
}

export default observer(DefaultTableBody);
