// eslint-disable-next-line no-unused-vars
import React, { Component } from 'react';
import { observer } from 'mobx-react';

class DefaultTableHead extends Component {
  render() {
    return (
      <thead>
              <tr>
                <th>@</th>{/* TODO: Checkbox */}
                { this.props.tableHead.map((col, index) => (
                  <th key={index}>{col.title}</th>
                )) }
              </tr>
            </thead>
    );
  }
}

export default observer(DefaultTableHead);
