import {
  decorate, observable, action, configure,
} from 'mobx';
import api from '../api';
import BaseStore from './BaseStore';
import utils from '../utils/commonUtil';

configure({ enforceActions: 'always' }); // strict mode always - use 'false' => '"never"', 'true' => '"observed"', '"strict"' => "'always'" instead

class SaleStore extends BaseStore {
  constructor(props) {
    super(props);
    this.initBase(); // triggering from BaseStore
    this.init = () => {
      // specific
      this.items = [];
      // cuRd = READ helper for view
      this.listHead = [
        { name: 'id', title: 'ID', sort: false },
        { name: 'product', title: 'Product', sort: false },
        { name: 'quantity', title: 'Quantity', sort: false },
        { name: 'price', title: 'Price', sort: false },
        { name: 'customer', title: 'Customer', sort: false },
        { name: 'stock', title: 'Stock', sort: false },
        { name: 'date', title: 'Date', sort: false },
        { name: 'created', title: 'Created', sort: true },
      ];
    };
    this.init();
  }

  async fetchItems() { // this method is overriden
    const itemList = await api.getSales();
    const itemSorted = itemList.data.sort((a, b) => {
      if (a.saleId < b.saleId) return -1;
      if (a.saleId > b.saleId) return 1;
      return 0;
    });

    itemSorted.forEach((item) => {
      this.push(this.items, {
        id: item.saleId,
        product: item.saleProduct,
        quantity: item.saleQuantity,
        price: item.salePrice,
        customer: item.saleCustomer,
        stock: item.saleStock,
        date: utils.formatDate(item.saleDate),
        created: utils.formatDate(item.created),
      });
    });
  }
}
decorate(SaleStore, {
  init: action,
  items: observable,
  listHead: observable,
});

export default new SaleStore();
