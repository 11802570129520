// eslint-disable-next-line no-unused-vars
import React, { Component } from 'react';
import { observer } from 'mobx-react';
import {
  Container, Row,
} from 'react-bootstrap';
import { LinearProgress } from '@material-ui/core';
import CustomerStore from '../../stores/CustomerStore';
import DefaultTable from '../common/Tables/DefaultTable';
import AlertTemplate from '../common/AlertTemplate';

class CustomerApp extends Component {
  async componentDidMount() {
    try {
      await CustomerStore.loadItems(); // Load Products
    } catch (e) {
      console.log(e.message);
      CustomerStore.set('alert', {
        show: true,
        variant: 'danger',
        msg: `Error! ${e.message}`,
      });
    } finally {
      console.log('finally');
    }
  }

  render() {
    return (
      <Container>
        {CustomerStore.loading && <LinearProgress variant="query" />}
        <AlertTemplate alert={CustomerStore.alert} setShow={CustomerStore.set} />
        <Row >
          <h2> Test</h2>
         <DefaultTable items={CustomerStore.items} tableHead={CustomerStore.listHead}/>
        </Row>
      </Container>
    );
  }
}

export default observer(CustomerApp);
