import React from 'react'; // eslint-disable-line no-unused-vars
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import AssignmentIcon from '@material-ui/icons/Assignment';
import DescriptionIcon from '@material-ui/icons/Description';
import HomeIcon from '@material-ui/icons/Home';
import CreateIcon from '@material-ui/icons/Create';
import SettingsIcon from '@material-ui/icons/Settings';
import LogoutIcon from '@material-ui/icons/PowerSettingsNew';
import { Link } from 'react-router-dom';
import config from '../../config';

class CtrListItem extends React.Component { // Controlled List Item -:)
  render() {
    // let content;
    // if (this.props.role && adalHelper.userHasRole(this.props.role)) {
    //   content = <ListItem {...this.props} ></ListItem>;
    // } else if (!this.props.role) { // if no role defined for that list show the list regardless of role
    const content = <ListItem {...this.props} ></ListItem>;
    // }
    return (<div>{content}</div>);
  }
}

class CtrExternalListItem extends React.Component { // Controlled External Links
  render() {
    // let content;
    // if (this.props.role && adalHelper.userHasRole(this.props.role)) {
    const content = (
      <a style={ { textDecoration: 'none' } }
        target='_blank'
        href={ config[this.props.href] }
        rel="noopener noreferrer" >
        <ListItem {...this.props} ></ListItem>
      </a>
    );
    // }
    return (<div>{content}</div>);
  }
}

const primaryListItems = (
  <div>
    <CtrListItem role={config.applicationRoles.Admin}
    component={Link} exact="true" to="/" button activeclassname="active">
      <ListItemIcon>
        <HomeIcon />
      </ListItemIcon>
      <ListItemText primary="Home" />
    </CtrListItem>
    <CtrListItem role={config.applicationRoles.Admin}
     component={Link} to="/sale/list" button activeclassname="active">
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Sale" />
    </CtrListItem>
    <CtrExternalListItem role={config.applicationRoles.Admin}
      button={true}
      href="reportUrl">
      <ListItemIcon>
        <DescriptionIcon />
      </ListItemIcon>
      <ListItemText primary="Reports" />
    </CtrExternalListItem>
    <CtrListItem role={config.applicationRoles.Admin}
     component={Link} to="/vendor/list" button activeclassname="active">
      <ListItemIcon>
        <DescriptionIcon />
      </ListItemIcon>
      <ListItemText primary="Vendors" />
    </CtrListItem>
    <CtrListItem role={config.applicationRoles.Admin}
     button component={Link} to="/stock/list" activeclassname="active">
      <ListItemIcon>
      <DescriptionIcon />
      </ListItemIcon>
      <ListItemText primary="Stocks" />
    </CtrListItem>
    <CtrListItem role={config.applicationRoles.Admin}
     button component={Link} to="/product/list" activeclassname="active">
      <ListItemIcon>
      <DescriptionIcon />
      </ListItemIcon>
      <ListItemText primary="Products" />
    </CtrListItem>
    <CtrListItem role={config.applicationRoles.Admin}
     button component={Link} to="/customer/list" activeclassname="active">
      <ListItemIcon>
      <CreateIcon />
      </ListItemIcon>
      <ListItemText primary="Customers" />
    </CtrListItem>
  </div>
);

const secondaryListItems = (
  <div>
    <CtrListItem role={config.applicationRoles.Admin}
     button>
      <ListItemIcon>
        <SettingsIcon />
      </ListItemIcon>
      <ListItemText primary="Settings" />
    </CtrListItem>
    <CtrListItem button component={Link} to="/logout" activeclassname="active">
      <ListItemIcon>
        <LogoutIcon />
      </ListItemIcon>
      <ListItemText primary="Logout" />
    </CtrListItem>
  </div>
);

export { primaryListItems, secondaryListItems };
