import {
  decorate, observable, action, configure,
} from 'mobx';
import api from '../api';
import utils from '../utils/commonUtil';

configure({ enforceActions: 'always' }); // strict mode always - use 'false' => '"never"', 'true' => '"observed"', '"strict"' => "'always'" instead

class ProductStore {
  constructor() {
    this.init = () => {
      // alert
      this.alert = {
        show: false,
        variant: 'success', // primary, secondary, success, danger, warning, info, light, dark
        msgHeading: '',
        msg: '',
        closeBtn: false,
      };
      // common
      this.showAlert = false;
      this.loading = false;
      // specific
      this.products = [];
      // cuRd = READ helper for view
      this.productsListHead = [
        { name: 'id', title: 'ID', sort: false },
        { name: 'name', title: 'Name', sort: false },
        { name: 'created', title: 'Created', sort: true },
      ];
      this.product = {
        productId: '',
        productName: '',
      };
    };
    this.init();

    this.push = (array, value) => {
      array.push(value);
    };
    this.setObj = (name, key, value) => {
      this[name][key] = value;
    };
    this.set = (name, value) => {
      this[name] = value;
    };
    this.saveProduct = async () => {
      const result = await this.save();
      return result;
    };
  }

  async loadProducts() {
    this.set('loading', true);

    if (this.products.length) {
      console.log('Products already loaded');
      return;
    }

    try {
      await this.fetchProducts();
    } finally {
      this.set('loading', false);
      // this.set('showAlert', true);
    }
    console.log('Products loaded');
  }

  async save() {
    this.set('loading', true);
    let result = null;
    try {
      if (this.product.productId === '') { // add new product
        result = await api.postProduct(this.product.productId, this.product);
      } else { // update existing product
        result = await api.updateProduct(this.product);
      }
    } finally {
      this.set('loading', false);
    }
    return result;
  }

  async fetchProducts() {
    const productList = await api.getProducts();
    const productSorted = productList.data.sort((a, b) => {
      if (a.productName < b.productName) return -1;
      if (a.productName > b.productName) return 1;
      return 0;
    });

    productSorted.forEach((product) => {
      this.push(this.products, {
        id: product.productId,
        name: product.productName,
        created: utils.formatDate(product.created),
      });
    });
  }
}
decorate(ProductStore, {
  init: action,
  push: action,
  set: action,
  setObj: action,
  showAlert: observable,
  alert: observable,
  loading: observable,
  product: observable,
  products: observable,
  productsListHead: observable,
  getCurrentRequest: action,
});

export default new ProductStore();
