// eslint-disable-next-line no-unused-vars
import React, { Component } from 'react';
import { observer } from 'mobx-react';
import {
  Container, Row,
} from 'react-bootstrap';
import { LinearProgress } from '@material-ui/core';
import VendorStore from '../../stores/VendorStore';
import DefaultTable from '../common/Tables/DefaultTable';
import AlertTemplate from '../common/AlertTemplate';

class VendorApp extends Component {
  async componentDidMount() {
    try {
      await VendorStore.loadItems(); // Load Products
    } catch (e) {
      console.log(e.message);
      VendorStore.set('alert', {
        show: true,
        variant: 'danger',
        msg: `Error! ${e.message}`,
      });
    } finally {
      console.log('finally');
    }
  }

  render() {
    return (
      <Container>
        {VendorStore.loading && <LinearProgress variant="query" />}
        <AlertTemplate alert={VendorStore.alert} setShow={VendorStore.set} />
        <Row >
          <h2> Test</h2>
         <DefaultTable items={VendorStore.items} tableHead={VendorStore.listHead}/>
        </Row>
      </Container>
    );
  }
}

export default observer(VendorApp);
