import {
  decorate, observable, action, configure,
} from 'mobx';

configure({ enforceActions: 'always' }); // strict mode always - use 'false' => '"never"', 'true' => '"observed"', '"strict"' => "'always'" instead

class BaseStore {
  constructor() {
    this.initBase = () => {
      // alert
      this.alert = {
        show: false,
        variant: 'success', // primary, secondary, success, danger, warning, info, light, dark
        msgHeading: '',
        msg: '',
        closeBtn: false,
      };
      // common
      this.showAlert = false;
      this.loading = false;
    };

    this.push = (array, value) => {
      array.push(value);
    };
    this.set = (name, value) => {
      this[name] = value;
    };
  }

  async loadItems() {
    this.set('loading', true);

    if (this.items.length) {
      console.log('Items already loaded');
      return;
    }

    try {
      await this.fetchItems();
    } finally {
      this.set('loading', false);
    }
    console.log('Items loaded');
  }

  async fetchItems() {
    console.log('Abstract method needs to override');
  }
}
decorate(BaseStore, {
  initBase: action,
  push: action,
  set: action,
  showAlert: observable,
  alert: observable,
  loading: observable,
});

export default BaseStore;
