import base64 from 'base-64';
// eslint-disable-next-line
import api from './api';

const config = {
  // requestsAppApiUrl: 'http://localhost:3021', //  TODO: this should read from below environment.json file which will be written by puppet (`http://${process.env.STORE_MANAGER_API_HOST}:${process.env.STORE_MANAGER_API_DB_PORT}`, )
  headers: {
    // Authorization: `Basic ${base64.encode(`${process.env.ACADEMIC_CALENDAR_API_SITS_USERNAME}:${process.env.ACADEMIC_CALENDAR_API_SITS_PASSWORD}`)}`,
    Authorization: `Basic ${base64.encode(`${'john'}:${'secret'}`)}`,
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
  applicationRoles: {
    User: 'User',
    Admin: 'Admin',
  },
  intialize: async () => {
    console.log('Loading core settings...');
    const response = await api.fetchAndDeserialise('/environment.json');
    if (!response.ok) {
      throw new Error('Could not load core settings');
    }
    Object.assign(config, response.data);
    console.log('Config:', config);
  },
};

export default config;
