import React from 'react';
// import PropTypes from 'prop-types';
// import Hidden from '@material-ui/core/Hidden';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import clsx from 'clsx';
import TopBar from './TopBar';
import { primaryListItems, secondaryListItems } from './MenuItems';
// import TopBar from './TopBar';

// const drawerWidth = 240;

// const styles = theme => ({
//   drawerHeader: theme.mixins.toolbar,
//   drawerPaper: {
//     width: 250,
//     [theme.breakpoints.up('md')]: {
//       width: drawerWidth,
//       position: 'relative',
//       height: '100%',
//     },
//   },
// });


class SideBar extends React.Component {
  constructor(props) {
    super(props);


    // const [open, setOpen] = React.useState(true);
    // const handleDrawerOpen = () => {
    //   setOpen(true);
    // };
    // const handleDrawerClose = () => {
    //   setOpen(false);
    // };
    this.fixedHeightPaper = clsx(this.props.classes.paper, this.props.classes.fixedHeight);

    this.state = {
      open: true,
    };
    this.handleDrawerOpen = () => {
      this.setState({ open: true });
    };
    this.handleDrawerClose = () => {
      this.setState({ open: false });
    };
    // this.handleDrawerToggle = () => {
    //   console.log(this.state.mobileOpen);
    //   this.setState({ mobileOpen: !this.state.mobileOpen });
    // };
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        <TopBar handleDrawerOpen={this.handleDrawerOpen}
          classes={classes} open={this.state.open}/>
        {/* <Hidden mdUp> */}
        <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !this.state.open && classes.drawerPaperClose),
        }}
        open={this.state.open}
      >
        <div className={classes.toolbarIcon}>
          <IconButton onClick={this.handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <List>{primaryListItems}</List>
        <Divider />
        <List>{secondaryListItems}</List>
      </Drawer>
      {/* </Hidden>
      <Hidden smDown implementation="css">
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !this.state.open && classes.drawerPaperClose),
        }}
        open={this.state.open}
      >
        <div className={classes.toolbarIcon}>
          <IconButton onClick={this.handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <List>{primaryListItems}</List>
        <Divider />
        <List>{secondaryListItems}</List>
      </Drawer>
      </Hidden> */}
      </div>
    );
  }
}

// SideBar.propTypes = {
//   classes: PropTypes.object.isRequired,
//   theme: PropTypes.object.isRequired,
// };

export default SideBar;
